//_ `Placeholders
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $input-placeholder-color !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $input-placeholder-color !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $input-placeholder-color !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $input-placeholder-color !important;
}

label:not(.file-label):not(.checkbox):not(.image-label):not(.is-boxed) {
  font-size: $size-6;
  font-weight: $weight-bold;
  text-transform: uppercase;
  padding-bottom: 5px;
  color: $label-color;
  display: inline-block;

  case-switch-yield & {
    display: flex;
    padding-bottom: 0;
  }

  @include touch {
    margin-top: $column-gap;
  }
}

select,
.select {
  font-size: $size-normal;

  &.is-small {
    border-radius: $control-radius-small;
    font-size: $size-small;
  }
  &.is-medium {
    font-size: $size-medium;
  }
  &.is-large {
    font-size: $size-large;
  }
}

case-input,
.select,
case-select-input {
  width: 100%;
}

//  Checkbox
.checkbox {
  display: block;
  position: relative;
  padding-left: $checkbox-spacing;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.is-checked .checkmark {
    background-color: $light;
    box-shadow: $checkbox-box-shadow-width $link inset;
    &:after {
      display: block;
    }
  }

  &.is-checked:focus .checkmark {
    box-shadow: $checkbox-box-shadow-width $link inset;
  }
  &:focus .checkmark {
    box-shadow: $checkbox-box-shadow-width rgba($grey, 0.75) inset;
  }
  &:hover .checkmark {
    box-shadow: $checkbox-box-shadow-width rgba($grey, 0.75) inset;
  }

  &.is-checked:hover .checkmark {
    box-shadow: $checkbox-box-shadow-width $link inset;
  }

  &.is-disabled {
    cursor: not-allowed;

    &.is-checked .checkmark {
      box-shadow: $checkbox-box-shadow-width $input-disabled-border-color inset;
      background-color: transparent;
    }
    &:hover .checkmark {
      box-shadow: 0 0 0 1px $input-disabled-color inset;
    }
    &.is-checked:hover .checkmark {
      box-shadow: $checkbox-box-shadow-width $input-disabled-border-color inset;
    }
  }
}

// Checkbox & radio
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 0;
  background-color: $light;
  box-shadow: 0 0 0 1px rgba($grey, 0.75) inset;
  transition: all 0.2s ease-in-out;

  .is-disabled & {
    box-shadow: 0 0 0 1px $input-disabled-color inset;
    background-color: transparent;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
    transition: all 0.2s ease-in-out;
  }
}

// File
.file-cta {
  &.file-remove {
    border: 0;
  }
}

// Radio

label.radio {
  padding-left: $checkbox-spacing;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: uppercase;
  color: $grey-dark;
  font-weight: $weight-bold;
  margin-bottom: 14px;

  input {
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
    opacity: 0;

    &.is-checked ~ .checkmark {
      background-color: $light;
      box-shadow: $checkbox-box-shadow-width $link inset;

      &:after {
        display: block;
      }
    }
    &:focus.is-checked ~ .checkmark {
      box-shadow: $checkbox-box-shadow-width $link inset;
    }
    &:focus ~ .checkmark {
      box-shadow: $checkbox-box-shadow-width $grey-light inset;
    }
  }

  &:hover input ~ .checkmark {
    box-shadow: $checkbox-box-shadow-width $grey-light inset;
  }
  &:hover input.is-checked ~ .checkmark {
    box-shadow: $checkbox-box-shadow-width $link inset;
  }

  &.is-disabled {
    cursor: not-allowed;

    &.is-checked .checkmark {
      box-shadow: $checkbox-box-shadow-width $input-disabled-border-color inset;
      background-color: transparent;
    }
    &:hover .checkmark {
      box-shadow: 0 0 0 1px $input-disabled-color inset;
    }
    &.is-checked:hover .checkmark {
      box-shadow: $checkbox-box-shadow-width $input-disabled-border-color inset;
    }
  }
}

.radio.is-boxed {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 70px;
  border-radius: $input-radius;
  background-color: $white-bis;
  border: 2px solid $border;
  position: relative;
  text-align: center;
  padding: $control-padding-vertical $control-padding-horizontal;

  img {
    display: none;
    position: absolute;
    top: -7px;
    right: -7px;
    width: 14px;
    height: 14px;
  }
  &.is-selected {
    border-color: $link;
    background-color: $white;

    img {
      display: block;
    }
  }
  &.is-disabled {
    border-color: transparent;
    opacity: 0.65;
  }

  .radio-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-weight: normal;
    text-transform: none;
    line-height: 1.5;
  }
}

.is-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}
